import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Seo/seo"
import PageBanner from "../components/PageBanner/pageBanner"
import Footer from "../components/Footer/footer";
import { TypographyStyle, GoogleFont } from 'react-typography'
import typography from '../utils/typography'
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";
import ContactForm from "../components/ContactForm/ContactForm";

class Kontakt extends React.Component {
  render () {
    const { intl, uri } = this.props
    return (
      <Layout location={uri} isStatic={true}>
        <SEO title={intl.formatMessage({ id: 'kontakt.title' })} />
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
        <PageBanner title={intl.formatMessage({ id: 'kontakt.title' })} />
        <div className="static-page-container">
          <ContactForm />
        </div>
        <Footer />
      </Layout>
    )
  }
}

Kontakt.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(Kontakt)
