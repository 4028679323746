import React, { Component } from 'react'
import { injectIntl, FormattedMessage } from "react-intl";
import styles from './contactForm.module.css'

class ContactForm extends Component {
  loadForm () {
    const lang = this.props.intl.locale;
    if (typeof window !== `undefined` && window.hbspt) {
      window.hbspt.forms.create({
        portalId: '5867677',
        formId: lang === 'pl' ? '818513ec-cc78-493e-97d3-4c130f397e00' : "0b3c4e3d-04d6-4359-97e8-4399fe3b7183",
        target: '#hubspotFormDiv'
      })
    }
  }

  componentDidMount () {
    if (!document.getElementById('hubspotForm')) {
      const script = document.createElement('script');
      script.id = 'hubspotForm';
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);
      script.addEventListener('load', () => this.loadForm());
    } else {
      this.loadForm()
    }
  }


  render () {
    const { intl } = this.props
    return (
      <div className={styles.contactForm + ' row'}>
        <div className="col-12">
          <h2><FormattedMessage id="kontakt.contact_us" /></h2>
          <p><FormattedMessage id="kontakt.desc" /></p>
          <ul className={styles.contactInfo}>
            <li><b><FormattedMessage id="kontakt.email" />: </b> <a href={"mailto:" + intl.formatMessage({ id: 'kontakt.email.val' })}><FormattedMessage id="kontakt.email.val" /></a></li>
            <li><b><FormattedMessage id="kontakt.phone" />: </b> <a href={"tel:" + intl.formatMessage({ id: 'kontakt.phone.val' })}><FormattedMessage id="kontakt.phone.val" /></a></li>
          </ul>
        </div>
      </div>
    )
  }
}

export default injectIntl(ContactForm);